import styles from "./FooterDisclaimer.module.css";
import HTMLRender from "@components/core/HTMLRender";
import get from "lodash/get";
import { useStore } from "@lib/store";
import { shallow } from "zustand/shallow";
import Link from "@src/components/elements/Link";

const FooterDisclaimer = (props) => {
  const currentProductType = useStore((state) => state.currentProductType);
  const data = get(props, "data.0", props);
  const store = useStore(
    (store) => ({
      locale: store.locale,
    }),
    shallow
  );
  if (!props.data.length > 0 || data === null || data === undefined) return null;

  return (
    <>
      <div id="footer_disclaimer" className={`${styles.disclaimer}`}>
        <HTMLRender data={currentProductType?.attributes?.footer_disclaimer} />
      </div>
      {["eu", "ro", "de", "cz", "it", "el", "es"].includes(store?.locale) ? null : (
        <div className="mt-lg flex">
          {(store && store?.locale === "en") || store?.locale === "lat" || store?.locale === "de" ? (
            <div className="text-center ltr:pr-sm rtl:pl-sm lg:ltr:text-left lg:rtl:text-right">
              <a
                target="_blank noreferrer"
                href={`https://capex.com/${store?.locale}/online-trading/sitemap`}
                className="text-sm text-[#999999] hover:text-secondaryHover lg:text-base"
              >
                Online Trading Sitemap
              </a>
            </div>
          ) : null}
          <div className="text-center text-sm text-[#999999] hover:text-secondaryHover lg:text-base lg:ltr:text-left lg:rtl:text-right">
            <Link text="Sitemap" href="/sitemap" />
          </div>
        </div>
      )}
    </>
  );
};

export default FooterDisclaimer;
